<template>
	<div>
		<approvals-order-purcharse></approvals-order-purcharse>
	</div>
</template>

<script>
	import ApprovalsOrderPurcharse from './ApprovalsOrderPurcharse.vue'

	export default {
		components: { 
			ApprovalsOrderPurcharse 
		},
		
	}
</script>